import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes.js' 

const router = createRouter({
    history: createWebHistory(),
    routes,
    // 每次切换路由页面滚动到顶部
    scrollBehavior () {
        return { top: 0 }
    }

})

export default router