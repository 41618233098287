import { ref } from 'vue';
import { sendMessage, submitForm } from '@/api/form';
export const useForm = () => {
  const checkName = function (rule, value, callback) {
    let newReg1 = /[`~!@#$%^&*()+=|{}':;',/\/\[\].<>/?~！@#￥%……&*（）——+|{}【】‘；：”“’。，、？]/;
    let newReg2 = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/;
    // if(!value || value.trim() === '') {
    //     return callback(new Error('请填入姓名'))
    // }else 
    if (newReg1.test(value)) {
      // 验证特殊字符
      return callback(new Error('输入的内容不能包含特殊字符'));
    } else if (newReg2.test(value)) {
      // 验证绝大多数emoji
      return callback(new Error('输入的内容不能包含特殊字符'));
    } else if (value && value.length > 5) {
      return callback(new Error('姓名应该在5个字以内'));
    } else {
      callback();
    }
  };
  const checkOrgName = function (rule, value, callback) {
    let newReg1 = /[`~!@#$%^&*()+=|{}':;',/\/\[\].<>/?~！@#￥%……&*（）——+|{}【】‘；：”“’。，、？]/;
    let newReg2 = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/;
    if (newReg1.test(value)) {
      // 验证特殊字符
      return callback(new Error('输入的内容不能包含特殊字符'));
    } else if (newReg2.test(value)) {
      // 验证绝大多数emoji
      return callback(new Error('输入的内容不能包含特殊字符'));
    } else if (value && value.length > 10) {
      return callback(new Error('机构名称应该在10个字以内'));
    } else {
      callback();
    }
  };
  const ruleFormRef = ref(null);
  const orgTypeList = ref([{
    label: '中医机构',
    value: '6'
  }, {
    label: '门诊部',
    value: '7'
  }, {
    label: '普通诊所',
    value: '8'
  }, {
    label: '单体/连锁药店',
    value: '9'
  }, {
    label: '定制开发',
    value: '10'
  }]);
  const formData = ref({
    name: undefined,
    orgname: undefined,
    orgtype: undefined,
    phone: undefined,
    captcha: undefined
  });
  const rules = ref({
    name: [
    // { required: true, message: '请填入姓名', trigger: 'blur' },
    // { min: 3, max: 5, message: '姓名应该在5个字以内', trigger: 'blur' },
    {
      validator: checkName,
      trigger: 'blur'
    }],
    orgname: [
    // { max: 10, message: '机构名称应该在10个字以内', trigger: 'blur' },
    // { pattern: /^([\u4e00-\u9fa5]{0,10})$/gi, message: '机构名称应该在10个字以内', trigger: 'blur' },
    {
      validator: checkOrgName,
      trigger: 'blur'
    }],
    // [`~!@#$%^&*()+=|{}':;',/\/\[\].<>/?~！@#￥%……&*（）——+|{}【】‘；：”“’。，、？]|[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]
    orgtype: [{
      required: true,
      message: '请选择机构类型',
      trigger: 'change'
    }],
    phone: [{
      required: true,
      message: '请填写手机号码',
      trigger: 'blur'
    },
    // { pattern: /^(((\d{3,4}-)?[0-9]{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/, message: '请输入正确的手机号码格式', trigger: 'blur' }
    {
      pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
      message: '请输入正确的手机号码格式',
      trigger: 'blur'
    }],
    captcha: [{
      required: true,
      message: '请输入验证码',
      trigger: 'blur'
    }, {
      pattern: /^\d{6}$/,
      message: '请输入6位数字的验证码',
      trigger: 'blur'
    }]
  });
  const btnText = ref('获取验证码');
  const btnDisabled = ref(false);
  // 定义验证码时间
  const count = 60;
  const time = ref(count);
  const initForm = function () {
    //初始化表单
    ruleFormRef.value.resetFields();
  };
  const handleSendCode = function () {
    //发送验证码
    if (!ruleFormRef.value) return;
    // 校验手机号码
    ruleFormRef.value.validateField('phone', isValid => {
      if (isValid) {
        // {errorCode: '0', errorMessage: '获取短信验证码成功', reqBusiness: ''}
        // {errorCode: '-1', errorMessage: '您当天已提交过注册信息，请勿重复提交。', reqBusiness: ''}
        getMessage().then(res => {
          if (res.errorCode != 0) {
            ElMessage.error(res.errorMessage);
          }
        });
        // 1.时间开始倒数
        // 2.按钮进入禁用状态
        // 3.如果倒计时结束，按钮回复可用状态，文字变为重新发送，时间重置
        // 4.倒计时过程中 文字为 多少s后重新发送
        let timer = setInterval(() => {
          btnText.value = `${time.value}s后重新发送`;
          btnDisabled.value = true;
          time.value--;
          if (time.value == 0) {
            btnText.value = `重新发送`;
            btnDisabled.value = false;
            time.value = count;
            clearInterval(timer);
          }
        }, 1000);
      }
    });
  };
  const getMessage = function () {
    //发送短信
    let params = {
      mobile: formData.value.phone //手机号码
    };

    return sendMessage(params);
  };
  const handleSubmit = function () {
    //提交表单
    return new Promise(resolve => {
      if (!ruleFormRef.value) return;
      ruleFormRef.value.validate(isValid => {
        if (isValid) {
          // 发送请求
          sendFrom().then(res => {
            // {errorCode: "0", errorMessage: "提交申请成功", reqBusiness: ""}
            if (res.errorCode == 0) {
              ElMessage.success(res.errorMessage);
              initForm();
            } else {
              ElMessage.error(res.errorMessage);
            }
            resolve(res);
          });
        }
      });
    });
  };
  const sendFrom = function () {
    //注册
    let params = {
      name: formData.value.name,
      //姓名
      clinic_name: formData.value.orgname,
      //门诊名称
      organization_type: formData.value.orgtype,
      //机构类型 1、中医  2、西医  3、中西医  4、药房/药店  5、其它
      mobile: formData.value.phone,
      //手机号码
      sms_code: formData.value.captcha //验证码
    };

    return submitForm(params);
  };
  return {
    ruleFormRef,
    orgTypeList,
    formData,
    rules,
    btnText,
    btnDisabled,
    handleSendCode,
    handleSubmit
  };
};