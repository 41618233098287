import myAxios from './axios'
import qs from 'qs'

export function submitForm(data) {    //注册
    return myAxios({
        url: '/api/openapi/register',
        method: 'post',
        data
    })
}

export function sendMessage(data) {   //发送短信
    return myAxios({
        url: '/api/openapi/sendsms',
        method: 'post',
        data
    })
}