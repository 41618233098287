const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            title: '保达科技首页'
        },
        component: () => import(/*webpackChunkName: "home"*/'@/views/Home')
    },
    {
        path: "/cloud",
        name: "Cloud",
        meta: {
            title: '保达云诊所'
        },
        component: () => import (/*webpackChunkName: "cloud"*/"@/views/Cloud")
    },
    {
        path: "/clinic",
        name: "Clinic",
        meta: {
            title: '保达门诊软件'
        },
        component: () => import (/*webpackChunkName: "clinic"*/"@/views/Clinic")
    },
    {
        path: "/hospital",
        name: "Hospital",
        meta: {
            title: '互联网医院'
        },
        component: () => import (/*webpackChunkName: "hospital"*/"@/views/Hospital")
    },
    {
        path: "/pharmacy",
        name: "Pharmacy",
        meta: {
            title: '药店管理软件'
        },
        component: () => import (/*webpackChunkName: "pharmacy"*/"@/views/Pharmacy")
    },
    {
        path: "/medicare",
        name: "Medicare",
        meta: {
            title: '医保代办申请'
        },
        component: () => import (/*webpackChunkName: "medicare"*/"@/views/Medicare")
    },
    {
        path: "/webhospital",
        name: "Webhospital",
        meta: {
            title: '互联网医院代办申请'
        },
        component: () => import (/*webpackChunkName: "webhospital"*/"@/views/Webhospital")
    },
    {
        path: "/approval",
        name: "Approval",
        meta: {
            title: '医疗许可代办申请'
        },
        component: () => import (/*webpackChunkName: "approval"*/"@/views/Approval")
    },
    {
        path: "/individuality",
        name: "Individuality",
        meta: {
            title: '个性化定制服务'
        },
        component: () => import (/*webpackChunkName: "individuality"*/"@/views/Individuality")
    },
    {
        path: "/case",
        name: "Case",
        meta: {
            title: '成功案例'
        },
        component: () => import (/*webpackChunkName: "case"*/"@/views/Case")
    },
    {
        path: "/about",
        name: "About",
        meta: {
            title: '关于保达'
        },
        component: () => import (/*webpackChunkName: "about"*/"@/views/About")
    },
    {
        path: "/casedetail",
        name: "CaseDetail",
        meta: {
            title: '保达详情'
        },
        component: () => import (/*webpackChunkName: "casedetail"*/"@/views/CaseDetail")
    },
    {
        path: "/medicine",
        name: "Medicine",
        meta: {
            title: '互联网药品许可证代办'
        },
        component: () => import (/*webpackChunkName: "medicine"*/"@/views/Medicine")
    },
    {
        path: "/evaluation",
        name: "Evaluation",
        meta: {
            title: '互联网药品许可证代办'
        },
        component: () => import (/*webpackChunkName: "evaluation"*/"@/views/Evaluation")
    }
]

export default routes