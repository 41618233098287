import { createVNode as _createVNode, resolveComponent as _resolveComponent, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import { ref } from 'vue';
import Header from './components/Header/index.vue';
import Footer from './components/Footer/index.vue';
export default {
  __name: 'App',
  setup(__props) {
    const locale = ref(zhCn);
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_unref(ElConfigProvider), {
        locale: locale.value
      }, {
        default: _withCtx(() => [_createVNode(Header), _createVNode(_component_router_view), _createVNode(Footer)]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};